import { render, staticRenderFns } from "./ProgressBarSmall.html?vue&type=template&id=68cc83aa&scoped=true&"
import script from "./ProgressBarSmall.ts?vue&type=script&lang=ts&"
export * from "./ProgressBarSmall.ts?vue&type=script&lang=ts&"
import style0 from "@/scss/components/progress-bar-small/_progress-bar-small.scss?vue&type=style&index=0&id=68cc83aa&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68cc83aa",
  null
  
)

export default component.exports