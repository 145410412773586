import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action, State as StateClass } from 'vuex-class';
// @ts-ignore
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import to from 'await-to-js';
import { saveAs } from 'file-saver';
import { AxiosResponse, AxiosError } from 'axios';
import { firebaseAxiosInstance } from '@/axios';
import Loader from '@/components/common/loader/Loader.vue';
import { State, StateSlice } from '@/store/models';
import { LoanFirestore } from '@/store/models/Loan';
import BigNumber from 'bignumber.js';
import { uniqBy } from 'lodash';
import { collections } from '@/vue';

@Component({
  components: {
    Loader,
  },
})

export default class AccountBalance extends Vue {
  @Prop({ })
  investmentsPromise!: Promise<any>;

  @StateClass assets!: State['assets'];
  @StateClass user!: State['user'];
  @StateClass loans!: StateSlice<LoanFirestore[]>;
  @StateClass premiumLoans!: StateSlice<LoanFirestore[]>;

  @Action getInvestedPremiumAssetLoans!: Function;

  @Getter getTotalInvested!: number;
  @Getter getSharesTotalInvested!: number;
  @Getter getLengthPaidInvestments!: number;

  @Getter getInvestmentsNonDeletedNonPremiumAsset!: any[];
  @Getter getPremiumTotalInvested!: number;

  @Action(addToastMessage) addToastMessage!: Function;

  @Action calculateIRR!: any;

  nonPremiumAssets: any = []

  isGeneratingPDF = false;
  previousYear: number = new Date().getFullYear() - 1;
  selectedYear: number = this.previousYear;

   // Determines the number of years shown in the tax statement year selector
  yearsToShow: number = 2;

  async mounted(): Promise<void> {
    await collections.investments;

    this.nonPremiumAssets = uniqBy(
      this.getInvestmentsNonDeletedNonPremiumAsset.map((investment): any => ({ asset: investment.asset, id: investment.asset.id })),
      'id',
    )
    .map(({ asset }): any => ({
      name: asset.name,
      totalEuro: this.getNonPremiumTotalInvested(asset.id),
      totalShares: this.getNonPremiumSharesTotalInvested(asset.id),
    }));
  }

  /**
   * Get the list of years for which the tax statement can be downloaded
   */
  get years(): number[] {
    return Array.from({ length: this.yearsToShow }, (v, i): number => this.previousYear - i);
  }

  get hasInvestments(): boolean {
    return this.getTotalInvested > 0;
  }

  get amountLoans(): number {
    return this.premiumLoans?.payload?.length;
  }

  async downloadStatement(): Promise<void> {
    this.isGeneratingPDF = true;
    const instance = await firebaseAxiosInstance();
    const [err, res] = await to<AxiosResponse, AxiosError>(instance.get('generateDividendsPDF', {
      params: {
        lang: this.$i18n.locale,
        year: this.selectedYear,
      },
      responseType: 'blob',
    }));

    this.isGeneratingPDF = false;

    if (err) {
      let errorMessage;

      if (err.response?.status === 404) {
        errorMessage = this.$t('dashboard.errors.noInvestmentsError');
      } else {
        errorMessage = this.$t('dashboard.errors.pdfError');
      }

      this.addToastMessage({
        text: errorMessage,
        type: 'danger',
      });
    } else {
      saveAs(res!.data, 'statement.pdf');
    }
  }

  @Watch('user', { immediate: true })
  onUserChange(): void {
    if (this.user?.id) {
      this.getInvestedPremiumAssetLoans({ investorId: this.user?.id });
    }
  }

  getNonPremiumTotalInvested(assetId: string): number {
    return this.getInvestmentsNonDeletedNonPremiumAsset
      .reduce((accumulator, investmentB): BigNumber => {
        if (investmentB.asset.id === assetId) {
          return accumulator.plus(investmentB.paidEuroTotal || 0);
        }

        return accumulator;
      }, new BigNumber(0)).toNumber();
  }

  getNonPremiumSharesTotalInvested(assetId: string): number {
    return this.getInvestmentsNonDeletedNonPremiumAsset
      .reduce((accumulator, investmentB): BigNumber => {
        if (investmentB.asset.id === assetId) {
          return accumulator.plus(investmentB.boughtSharesTotal || 0);
        }

        return accumulator;
      }, new BigNumber(0)).toNumber();
  }
}
