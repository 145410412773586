import { Vue, Prop, Component } from 'vue-property-decorator';
import { Action, Getter, State as ClassState } from 'vuex-class';
import LazyImage from '@/components/common/lazyImage/LazyImage.vue';
import { State } from '@/store/models';
import { Asset } from '@/store/models/asset';
import Loader from '@/components/common/loader/Loader.vue';
import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';
import FundDocs from '@/components/common/fund-docs/FundDocs.vue';
import ProgressBarSmall from '@/components/common/progress-bar-small/ProgressBarSmall.vue';
import NetValue from '@/components/common/net-value/netValue.vue';

@Component({
  components: {
    Loader,
    WithDownloads,
    FundDocs,
    ProgressBarSmall,
    NetValue,
    LazyImage,
  },
})

export default class PropertiesGridFund extends Vue {
  @Prop({ default: (): undefined => undefined }) asset!: Asset;
  @ClassState user!: State['user'];
  @Getter isUserLoggedIn!: boolean;
  @Action openModal!: Function;

  get assetAddress(): string {
    return this.asset ? this.asset.city : '';
  }

  get placeHolderImage(): Object {
    return require('@/assets/images/property/properties-placeholder.jpg');
  }
}
