import { render, staticRenderFns } from "./AccountInvestments.html?vue&type=template&id=3f3eb14d&scoped=true&"
import script from "./AccountInvestments.ts?vue&type=script&lang=ts&"
export * from "./AccountInvestments.ts?vue&type=script&lang=ts&"
import style0 from "@/scss/components/account/_investments.scss?vue&type=style&index=0&id=3f3eb14d&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f3eb14d",
  null
  
)

export default component.exports