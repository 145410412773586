import { Vue, Component, Prop } from 'vue-property-decorator';
import { State as StateClass, Getter } from 'vuex-class';
import BigNumber from 'bignumber.js';
import { getPaymentDate } from '@/store/modules/dividends';
import LazyImage from '@/components/common/lazyImage/LazyImage.vue';
import Loader from '@/components/common/loader/Loader.vue';
import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';
import { InvestmentsArray } from '@/store/modules/investments';
import { State } from '@/store/models';
import { DownloadPath } from '@/store/modules/downloads/downloads';
import { Investment, Payment } from '@/store/models/investment';
import { Asset } from '@/store/models/asset';

// const VueScrollTo = require('vue-scrollto');

// Vue.use(VueScrollTo);
@Component({
  components: {
    Loader,
    WithDownloads,
    LazyImage,
  },
})

export default class AccountInvestments extends Vue {
  @Prop({}) investment!: Investment;
  @Prop({}) asset!: Asset;

  pageSize: number = 5;
  currentPage: number = this.pageSize;
  showPayments: { [key: string]: { [key: string]: any } } = {};
  maxPayments: number = 5; // max payments shown per investment

  @StateClass assets!: State['assets'];
  // Workaround for Vue's reactivity caveat.
  @StateClass((state): State['payments'] => [...state.payments]) payments!: State['payments'];

  @Getter investmentsLoadMore!: Function;
  @Getter getAssetById!: Function;
  @Getter isDownloadsProcessing!: Function;
  @Getter getPaidPaymentsByInvestmentId!: Function;

  /**
   * Computed property to get the investments.
   */
  get investments(): InvestmentsArray<State['investments']> {
    return this.investmentsLoadMore(this.currentPage);
  }

  /**
   * Returns whether we've reached the
   * end of the transaction list on
   * the frontend.
   *
   * @returns {boolean}
   */
  get endOfTransactionsList(): boolean {
    return this.investments && this.investments.length === this.investments.totalLength;
  }

  get investmentsDownloadsProcessing(): boolean {
    const path: DownloadPath = { collection: 'assets', id: '', properties: 'images' };
    return this.investments.some((investment): boolean => this.isDownloadsProcessing({ ...path, id: (investment as any).assetId }));
  }

  showAllPayments(id: string): void {
    const showPayments = this.showPayments;
    showPayments[id] = showPayments[id] ? { ...showPayments[id], max: !showPayments[id].max } : { max: true };
    Vue.set(this.showPayments, id, showPayments[id]);
  }

  onInvestmentExpand(id: string): void {
    const showPayments = { ...this.showPayments };
    showPayments[id] = showPayments[id] ? { ...showPayments[id], expand: !showPayments[id].expand } : { expand: true };
    Vue.set(this.showPayments, id, showPayments[id]);
  }

  reverseArray(array: any[]): any[] {
    return [...array].reverse();
  }

  // Opening the support box from AccountDashboard
  handleSupport(): void {
    this.$emit('handle-support-event');
  }

  loadMoreInvestments(): void {
    this.currentPage += this.pageSize;
  }

  isPaymentEnded(payment: Payment): boolean {
    return payment.ended ? new BigNumber(payment.ended.seconds).times(1000).minus(Date.now()).toNumber() <= 0 : false;
  }

  get dummyPayments(): Payment[] {
    return ([
      ...this.getPaidPaymentsByInvestmentId(this.investment.id),
    ] as Payment[]).sort((a, b): number => {
      if (getPaymentDate(a) > getPaymentDate(b)) {
        return -1;
      }
      if (getPaymentDate(a) < getPaymentDate(b)) {
        return 1;
      }
      return 0;
    });
  }
}
