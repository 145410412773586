import to from 'await-to-js';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import AccountInvestments from '@/components/account/investments/AccountInvestments.vue';
import FundDocs from '@/components/common/fund-docs/FundDocs.vue';
import LazyImage from '@/components/common/lazyImage/LazyImage.vue';
import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';
import { db, storage } from '@/firebase';
import { State as StateClass } from '@/store/models';
import { Asset } from '@/store/models/asset';
import { Investment, PersonalDividend } from '@/store/models/investment';
import { PivotedDividend } from '@/store/modules/dividends';
import { collections } from '@/vue';
import BigNumber from 'bignumber.js';

type InterestNotice = { ref: string, name: string, path: string }

@Component({
  components: {
    WithDownloads,
    FundDocs,
    AccountInvestments,
    LazyImage,
  },
})

export default class AccountSingleFund extends Vue {
  @Prop({ default: (): undefined => undefined, required: true }) asset!: Asset;
  @Prop({ default: (): undefined => undefined }) investment!: Investment;

  @State('state') payments!: StateClass['payments'];
  @State user!: StateClass['user'];

  @Action getDividendsByInvestment!: Function;
  @Getter getInvestmentByAsset!: Function;
  @Getter investmentHasPaidPayments!: Function;
  @Getter getTotalDividends!: Number;
  @Getter getPremiumTotalDividends!: any;
  @Getter getInvestmentsNonDeletedNonPremiumAsset!: any[];
  @Getter getPersonalDividendsByAsset!: PersonalDividend[];
  @Getter getPersonalDividendsByAssetPivoted!: PivotedDividend[];

  collections = collections;
  promisedAssetsCollection = collections.assets;
  showFundDocs = false;
  handleSupport = false;
  showAllDividends = false;
  showHistory = true;
  historyView: 'investments' | 'dividends' = 'investments';
  dividendsPromise: Promise<any> = Promise.resolve();
  interestNotices: { ref: string, name: string, path: string }[] = [];
  dividends: any = [];
  totalDividends = 0

  dividendTypes = {
    A: this.$t('dashboard.dividendHistory.ANotesInterest'),
    C: this.$t('dashboard.dividendHistory.CNotesInterest'),
    allNotes: this.$t('dashboard.dividendHistory.allNotesInterest'),
  };

  @Watch('asset', { immediate: true })
  async onAssetChange(newAsset: Asset): Promise<void> {
    if (!newAsset.id) {
      return;
    }

    const investorRef = db.collection('investors').doc(this.user!.id);
    const assetRef = db.collection('assets').doc(this.asset!.id);

    const [getAssetError, getAssetSuccess] = await to(
      db.collection('investments')
        .where('asset', '==', assetRef)
        .where('investor', '==', investorRef)
        .get(),
    );

    if (getAssetError) {
      throw getAssetError.message;
    }

    const [getEarningsError, getEarningsSuccess] = await to(
      getAssetSuccess!.docs[0].ref
        .collection('earnings')
        .where('deleted', '==', false)
        .orderBy('period', 'desc')
        .get(),
    );

    if (getEarningsError) {
      throw getEarningsError.message;
    }

    this.dividends = getEarningsSuccess!.docs.map((doc): any => ({ id: doc.id, ...doc.data() }));

    if (!this.getPersonalDividendsByAssetPivoted.length) {
      this.getDividendsByInvestment({ id: this.investment.id, asset: this.asset });
    }

    if (this.asset.premium) {
      this.totalDividends = this.getPremiumTotalDividends;
    } else {
      this.totalDividends = this.getNonPremiumTotalDividends(newAsset.id);
    }
  }

  getNonPremiumTotalDividends(assetId: string): number {
    return this.getInvestmentsNonDeletedNonPremiumAsset
      .reduce((accumulator, investmentB): BigNumber => {
        if (investmentB.asset.id === assetId) {
          return accumulator.plus(investmentB.totalDividends || 0);
        }

        return accumulator;
      }, new BigNumber(0)).toNumber();
  }

  @Watch('getPersonalDividendsByAsset', { immediate: true })
  onNewGetPersonalDividendsByAsset(): void {
    this.updateInterestNotices();
  }

  get getTotalShares(): number {
    return this.investment?.boughtSharesTotal || 0;
  }

  get hasPaidInvestments(): boolean {
    return this.investment?.id && this.investmentHasPaidPayments(this.investment.id);
  }

  get noteType(): string {
    return `${this.user?.type} type`;
  }

  get lang(): string {
    return this.$route.params.lang;
  }

  getInterestNotice(ref: string): InterestNotice {
    return this.interestNotices.find((notice): boolean => notice.ref === ref)!;
  }

  updateInterestNotices(): void {
    const filePath = async (ref: string): Promise<InterestNotice> => {
      const splittedPath = ref.split('/');
      const [pathError, path] = await to(storage.ref(ref).getDownloadURL());
      if (pathError) {
        // eslint-disable-next-line no-console
        console.warn(pathError);
        return { name: splittedPath[splittedPath.length - 1], path: '/error/404', ref };
      }
      return { name: splittedPath[splittedPath.length - 1], path, ref };
    };
    Promise.all(
      this.getPersonalDividendsByAssetPivoted.map((interest): any =>
        Promise.all(
          interest.interestNotice.map((interestNotice): any => filePath(interestNotice)),
        ).then((interestNotices): void => {
          this.interestNotices.push(...interestNotices);
        })),
    );
  }

  changeView(view: 'investments' | 'dividends'): void {
    this.historyView = view;
  }

  formatNumberTwoDecimalPlaces(num: number): string {
    return num.toLocaleString(this.lang, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
}
