import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Action, State as StateClass } from 'vuex-class';
// @ts-ignore
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import Loader from '@/components/common/loader/Loader.vue';
import { State } from '@/store/models';

@Component({
  components: {
    Loader,
  },
})
export default class Support extends Vue {
  // If a 'toggled' prop is passed the modal can be controlled from outside of the component
  // but it still handles its own inner logic
  @Prop({ default: null }) toggled?: boolean | null;

  handleSupport: boolean = false;
  mailBody: string = '';

  @Action(addToastMessage) addToastMessage!: Function;
  @Action contactSupportByMail!: Function;
  @StateClass support!: State['support'];

  @Watch('support')
  onSupportChanged(newSupport: any, oldSupport: any): void {
    if (newSupport && newSupport.status === 'error' && oldSupport.status === 'processing') {
      this.addToastMessage({
        text: newSupport.error,
        type: 'danger',
      });
    }

    if (newSupport && newSupport.status === 'success' && oldSupport.status === 'processing') {
      this.addToastMessage({
        text: `${this.$t('dashboard.support.messageSent')}. ${this.$t('dashboard.support.youWillBeAnswered')}.`,
        type: 'success',
      });
    }
  }

  @Watch('toggled')
  onToggleChanged(newToggle: boolean): void {
    this.handleSupport = newToggle;
  }

  get hasController(): boolean {
    return !(this.toggled === null);
  }

  sendMailToSupport(mailBody: string): void {
    this.contactSupportByMail({ mailBody });
    this.mailBody = '';
  }

  onClick(): void {
    this.handleSupport = !this.handleSupport;
    if (this.hasController) {
      this.$emit('toggle');
    }
  }
}
