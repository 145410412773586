import { Vue, Component } from 'vue-property-decorator';
import { State as StateClass, Getter } from 'vuex-class';
import { IdentRouteNames } from '@/modules/identification/identRouteNames';
import { State } from '@/store/models';
import Loader from '@/components/common/loader/Loader.vue';
import Support from '@/components/common/support/Support.vue';
import { IdentificationRequestStatus } from '@/store/models/identificationRequest';
import { intercom, name } from '../../../../../whitelabel.config';

@Component({
  components: {
    Loader,
    Support,
  },
})
export default class AccountSettingsIdentification extends Vue {
  intercom = intercom;
  clientName = name;
  IdentRouteNames = IdentRouteNames;

  toggleSupport: boolean = false;

  @StateClass investor!: State['investor'];
  @StateClass identificationRequest!: State['identificationRequest'];

  @Getter isInvestor!: boolean;

  get pendingIdentificationRequest(): boolean {
    return this.identificationRequest?.status === IdentificationRequestStatus.Initial;
  }

  get rejectedIdentificationRequest(): boolean {
    return this.identificationRequest?.status === IdentificationRequestStatus.Rejected;
  }
}
