import { Vue, Component } from 'vue-property-decorator';
import { State as StateClass, Getter } from 'vuex-class';
import { State } from '@/store/models';
import { IdentificationRequestStatus } from '@/store/models/identificationRequest';

@Component({
  components: {
  },
})
export default class AccountUpgrade extends Vue {
  @StateClass identificationRequest!: State['identificationRequest'];

  @Getter isEligibleToInvest!: Function;

  get pendingIdentificationRequest(): boolean {
    return this.identificationRequest?.status === IdentificationRequestStatus.Initial;
  }

  get rejectedIdentificationRequest(): boolean {
    return this.identificationRequest?.status === IdentificationRequestStatus.Rejected;
  }

  dismissInfobox(): void {
    localStorage.setItem('showUpgradeInfobox', 'false');
    this.$emit('dismiss');
  }
}
