import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action, State as StateClass } from 'vuex-class';
import Loader from '@/components/common/loader/Loader.vue';
import { State } from '@/store/models';
import { collections } from '@/vue';
import BigNumber from 'bignumber.js';
import { uniqBy } from 'lodash';
import { Investment } from '@/store/models/investment';

@Component({
  components: {
    Loader,
  },
})

export default class AccountDividends extends Vue {
  @Prop({}) investmentsPromise!: Promise<any>;

  nonPremiumAssets: any = [];
  premiumAssets: any = [];

  @Getter getInvestmentsNonDeletedPremiumAsset!: Investment[];
  @Getter getInvestmentsNonDeletedNonPremiumAsset!: Investment[];
  @Getter getInvestmentsNonDeletedAsset!: Investment[];

  @Action calculateIRR!: any;

  @StateClass investments!: State['investments'];

  async mounted(): Promise<void> {
    await collections.investments;

    this.nonPremiumAssets = await Promise.all(
      uniqBy(
        this.getInvestmentsNonDeletedNonPremiumAsset.map((investment): any => ({ asset: investment.asset, id: investment.asset.id })),
        'id',
      )
      .map(async ({ asset }): Promise<any> => ({
        name: asset.name,
        total: this.getTotalDividends(asset.id),
        irr: await this.calculateIRR({ assetId: asset.id }),
      })),
    );
    this.premiumAssets = await Promise.all(
      uniqBy(
        this.getInvestmentsNonDeletedPremiumAsset.map((investment): any => ({ asset: investment.asset, id: investment.asset.id })),
        'id',
      )
      .map(async ({ asset }): Promise<any> => ({
        name: asset.name,
        total: this.getTotalDividends(asset.id),
        irr: await this.calculateIRR({ assetId: asset.id }),
      })),
    );
  }

  getTotalDividends(assetId: string): number {
    return this.getInvestmentsNonDeletedAsset
      .reduce((accumulator, investmentB): BigNumber => {
        if (investmentB.asset.id === assetId) {
          return accumulator.plus(investmentB.totalDividends || 0);
        }

        return accumulator;
      }, new BigNumber(0)).toNumber();
  }
}
