import { Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Component from 'vue-class-component';
import Loader from '@/components/common/loader/Loader.vue';
import AccountSingleFund from '@/components/account/single-fund/AccountSingleFund.vue';
import { AssetsArray } from '@/store/modules/assets';
import { DownloadPath } from '@/store/modules/downloads/downloads';
import { Asset } from '@/store/models/asset';
import { State } from '@/store/models';
import { Investment } from '@/store/models/investment';

@Component({
  components: {
    AccountSingleFund,
    Loader,
  },
})

export default class AccountFunds extends Vue {
  @Prop({ default: 'Title' })
  title!: Asset[];

  @Prop({ default: (): any[] => [] })
  assets!: Asset[];

  @Prop({ })
  assetsPromise!: Promise<any>;

  currentAssetsPosition = 2;
  assetsPerLoadMore = 2;

  @Getter getInvestmentByAsset!: Function;
  @Getter isDownloadsProcessing!: Function;
  @Getter assetsLoadMore!: Function;

  get investmentsDownloadsProcessing(): boolean {
    const path: DownloadPath = { collection: 'assets', id: '', properties: 'images' };
    return this.assets.some((asset): boolean => this.isDownloadsProcessing({ ...path, id: asset.id }));
  }

  /**
   * Computed property to get the assets.
   */
  get filteredAssets(): AssetsArray<State['investments']> {
    return this.assetsLoadMore(this.currentAssetsPosition, this.assets).sort((a, b): number => a.premium - b.premium);
  }

  /**
   * Returns whether we've reached the
   * end of the assets list on
   * the frontend.
   *
   * @returns {boolean}
   */
  get endOfAssetsList(): boolean {
    return this.filteredAssets && this.filteredAssets.length === this.filteredAssets.totalLength;
  }

  /**
   * Get investment instance by asset ID.
   */
  investmentByAssetId(assetId: string): Investment {
    return this.getInvestmentByAsset(assetId);
  }

  loadMoreAssets(): void {
    this.currentAssetsPosition += this.assetsPerLoadMore;
  }

  loadLessAssets(): void {
    if (this.currentAssetsPosition > this.assetsPerLoadMore) {
      this.currentAssetsPosition = this.assetsPerLoadMore;
    }
  }
}
