import { render, staticRenderFns } from "./Support.html?vue&type=template&id=2b9e34da&scoped=true&"
import script from "./Support.ts?vue&type=script&lang=ts&"
export * from "./Support.ts?vue&type=script&lang=ts&"
import style0 from "@/scss/components/support/_support.scss?vue&type=style&index=0&id=2b9e34da&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b9e34da",
  null
  
)

export default component.exports