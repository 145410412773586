import { render, staticRenderFns } from "./AccountUpgrade.html?vue&type=template&id=595caf1c&scoped=true&"
import script from "./AccountUpgrade.ts?vue&type=script&lang=ts&"
export * from "./AccountUpgrade.ts?vue&type=script&lang=ts&"
import style0 from "@/scss/components/account/_upgrade.scss?vue&type=style&index=0&id=595caf1c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "595caf1c",
  null
  
)

export default component.exports