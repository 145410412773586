import { Vue, Prop, Component } from 'vue-property-decorator';
import PropertiesGridFund from '@/components/properties/grid-list/grid/PropertiesGridFund.vue';
import { Asset } from '@/store/models/asset';
import Loader from '@/components/common/loader/Loader.vue';

@Component({
  components: {
    PropertiesGridFund,
    Loader,
  },
})

export default class PropertiesGridList extends Vue {
  @Prop({ default: (): Asset[] => [] })
  assets!: Asset[];

  @Prop({ })
  assetsPromise!: Promise<any>;
}
